.unitBlk_cont .block-wrap.unitDestination {
  /*min-height: 100%;*/
  position: relative;
  box-shadow: none;
}

.close-link {
  position: absolute;
  right: 8px;
  top: 7px;
  background: #dd2c28;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.close-link i {
  line-height: 1;
  color: #fff;
}
.unitDestination .close-link i {
  color: #fff;
  line-height: 0.7;
  display: flex;
  margin: auto;
}
.unitDestination .close-link:hover {
  background: rgb(221, 44, 40, 0.7);
  color: #fff;
}
.unitDestination .close-link:hover i {
  color: #000;
}

/* .unitDestination a:hover {
    position: absolute;
    right: 22px;
    top: 7px;
    background: #DD2C28;
    color: #fff;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    padding-top: 2px;
} */
.unitDestination .control-link {
  font-size: 0.857143rem;
}
.unitDestination .desctxt {
  display: block;
}
.unitDestination .postlive {
  max-width: 115px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.unitDestination .fbpost {
  padding: 7px 15px;
  font-size: 1rem;
  border-radius: 3px !important;
  font-weight: 500;
}
.unitDestination .fbpost i {
  margin-right: 5px;
}

.unitDestination .btn-fbpost {
  background: #3b5998;
  border: #3b5998;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.unitDestination .btn-fbpost:hover,
.unitDestination .btn-fbpost:focus,
.unitDestination .btn-fbpost:not(:disabled):not(.disabled):active {
  background: #3b5998;
  border: #3b5998;
}

.unitDestination .btn-twitchpost {
  margin-bottom: calc(100% - 89%);
  background: #6441a5;
  border: #6441a5;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-twitchpost:hover,
.unitDestination .btn-twitchpost:focus,
.unitDestination .btn-twitchpost:not(:disabled):not(.disabled):active {
  background: #6441a5;
  border: #6441a5;
}

.unitDestination .btn-boxcastpost {
  margin-bottom: calc(100% - 92%);
  background: #343434;
  border: #343434;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-boxcastpost:hover,
.unitDestination .btn-boxcastpost:focus,
.unitDestination .btn-boxcastpost:not(:disabled):not(.disabled):active {
  background: #343434;
  border: #343434;
}

.unitDestination .btn-vimeopost {
  margin-bottom: calc(100% - 92%);
  background: #1e1a1b;
  border: #1e1a1b;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-vimeopost:hover,
.unitDestination .btn-vimeopost:focus,
.unitDestination .btn-vimeopost:not(:disabled):not(.disabled):active {
  background: #1e1a1b;
  border: #1e1a1b;
}

.unitDestination .btn-restreampost {
  margin-bottom: calc(100% - 86%);
  background: #f12e0e;
  border: #f12e0e;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-restreampost:hover,
.unitDestination .btn-restreampost:focus,
.unitDestination .btn-restreampost:not(:disabled):not(.disabled):active {
  background: #f12e0e;
  border: #f12e0e;
}

.unitDestination .btn-easylivepost {
  margin-bottom: calc(100% - 86%);
  background: #58ac25;
  border: #58ac25;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-easylivepost:hover,
.unitDestination .btn-easylivepost:focus,
.unitDestination .btn-easylivepost:not(:disabled):not(.disabled):active {
  background: #58ac25;
  border: #58ac25;
}

.unitDestination .btn-pspost {
  background: #40a4c4;
  border: #40a4c4;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-pspostlogout {
  margin-bottom: calc(100% - 90%);
  background: #40a4c4;
  border: #40a4c4;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-pspost:hover,
.unitDestination .btn-pspost:focus,
.unitDestination .btn-pspost:not(:disabled):not(.disabled):active,
.unitDestination .btn-pspostlogout:hover,
.unitDestination .btn-pspostlogout:focus,
.unitDestination .btn-pspostlogout:not(:disabled):not(.disabled):active {
  background: #40a4c4;
  border: #40a4c4;
}

.unitDestination .btn-ytpost {
  border-radius: 25px;
  background: #dd2c28;
  border: #dd2c28;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 120px);
  position: absolute;
  right: 10px;
  bottom: 0.857143rem;
}
.unitDestination .btn-ytpost:hover,
.unitDestination .btn-ytpost:focus,
.unitDestination .btn-ytpost:not(:disabled):not(.disabled):active {
  border-radius: 25px;
  background: #dd2c28;
  border: #dd2c28;
}

.unitDestination .btn-linkedinpost {
  border-radius: 25px;
  background: #006599;
  border: #006599;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitDestination .btn-switchboardpost:active:hover,
.unitDestination .btn-switchboardpost:hover,
.unitDestination .btn-switchboardpost:focus,
.unitDestination .btn-switchboardpost:not(:disabled):not(.disabled):active {
  border-radius: 25px;
  background: #6c3;
  border: #33bbe8;
}
.unitDestination .btn-switchboardpost {
  border-radius: 25px;
  background: #6c3;
  border: #33bbe8;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: calc(100% - 86%);
}
.unitDestination .btn-linkedinpost:active:hover,
.unitDestination .btn-linkedinpost:hover,
.unitDestination .btn-linkedinpost:focus,
.unitDestination .btn-linkedinpost:not(:disabled):not(.disabled):active {
  border-radius: 25px;
  background: #006599;
  border: #006599;
}
.solounit-main .container-min .loadytchat .btn-ytpost {
  background: #dd2c28;
  border: #dd2c28;
  font-weight: 600;
  font-size: 1rem;
}
.destinationimage .img {
  /* width: 40px; */
  height: 40px;
  display: block;
}

.destinationimage .img img {
  max-height: 100%;
}

.channel-name-value {
  font-weight: bold;
  color: #260a5a;
  text-align: left;
}

.channel-name {
  min-width: 100%;
}
