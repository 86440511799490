header {
  padding-bottom: 4px;
  background: #000;
  padding-left: 15px;
  padding-right: 15px;
}
.liveu_page .navbar {
  background: #000;
}

.user_svg path {
  fill: #fff;
}
.hamburgar_svg path {
  fill: #fff;
}

.lagNav .dropdown-toggle::after {
  display: none;
}
header .socialIcon {
  width: 20px;
  margin-right: 15px;
}
header .nav .dropdown-menu .dropdown-item {
  min-width: 240px;
  padding: 5px 15px;
}
header .nav .dropdown-menu .dropdown-item i {
  margin-right: 7px;
}

header .nav button {
  font-size: 1rem;
}
.navbar-header {
  padding: 0;
  width: 166px;
  margin: 15px 30px 10px 15px;
  height: 54px;
}

.navsection .leftNav .nav-item > a {
  color: #fff;
  text-transform: uppercase;
  padding: 0px 10px 7px !important;
  font-size: 1.1429rem;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.navsection .leftNav .active.nav-item > a,
.navsection .leftNav .nav-item:hover > a {
  color: #fff;
  border-bottom: 2px solid #e34729;
  text-decoration: none;
}

.navsection .rightNav li.nav-item > a {
  padding: 0px 10px 7px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
  display: block;
}
.navsection .rightNav .nav-item > a {
  border-bottom: 3px solid transparent;
}
.navsection .rightNav .nav-item:hover > a {
  border-bottom: 3px solid #e34729;
}
header .userName {
  color: #fff;
  padding-right: 0px;
  line-height: 1.6;
}
/* 
header .navbar-dark .navbar-brand{
    padding-left: 1rem;
    padding-top: 7px;
    padding-bottom: 11px;
    padding-right: 1rem;
}
header .navbar-collapse .lagNav{margin-left: 15px;} */

.navMainCol .dropdown-menu.show {
  display: block;
  margin-top: 1.28571rem;
}

.navMainCol .langDropdown.dropdown-menu.show {
  display: block;
  margin-top: 1.28571rem;
  right: 0;
  min-width: 190px;
  left: inherit !important;
}
@media (max-width: 767px) {
  .rightNav ul.nav .nav-item {
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media (min-width: 768px) {
  .logoMainCol {
    max-width: 211px;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .navMainCol {
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .rightNav ul.nav {
    margin-right: -13px;
    margin-bottom: 9px;
  }

  .rightNav ul.nav .nav-item .dropdown > a {
    padding: 0 0.5rem;
    display: flex;
  }
}
@media (max-width: 1400px) and (min-width: 991px) {
  .navMainCol .dropdown-menu.show {
    right: 0px !important;
    left: auto !important;
    transform: translate3d(0px, 21px, 0px) !important;
    -webkit-transform: translate3d(0px, 21px, 0px) !important;
    -moz-transform: translate3d(0px, 21px, 0px) !important;
    -ms-transform: translate3d(0px, 21px, 0px) !important;
    -o-transform: translate3d(0px, 21px, 0px) !important;
  }
}
@media (max-width: 767px) {
  .navMainCol {
    width: 100%;
    padding-top: 5px;
  }
  header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  header nav.navbar {
    padding: 0;
  }
  header .logoMainCol {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
  }

  .navsection .rightNav li.nav-item > a {
    padding: 0 6px 0px;
    border: 0 !important;
  }
  .navMainCol .dropdown.show:after {
    content: '';
    position: fixed;
    background: #0000005e;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
  }
}

.navbar-nav span[role='button'] {
  padding: 15px;
  display: inline-block;
  line-height: 20px;
}
.referralCodeLink {
  color: #fff;
}

.referralCodeLink:hover {
  color: #e44920;
}

.refer-sketch {
  color: darkgray;
}
@media screen and (max-width: 991px) {
  .navsection .leftNav .nav-item > a {
    padding: 0px 5px 7px !important;
    font-size: 14px;
    line-height: 23px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 933px) { 
  .leftNav .nav-item > a{
    margin: 0px 5px !important;
    white-space: nowrap;
    padding: 0px 5px 7px !important;
  }
  .nav{
    flex-wrap: nowrap;
  }
}