.youtubeevent .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -45px;
}
.youtubeevent {
  position: relative;
}
.youtubeevent .panel-body {
  margin: 0 auto;
}
.youtubeevent .page-wrap {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.youtubeevent .form-group::after {
  clear: both;
  display: block;
  content: '';
}
.youtubeevent-popup h1 {
  color: #000;
  font-size: 24px;
  font-weight: 300;
}
.youtubeevent-popup .modal-header {
  border-bottom: 1px solid #f1592b;
}
.youtubeevent-popup .modal-footer {
  clear: both;
}
.youtubeevent .col-left {
  font-size: 1.1429rem;
  font-weight: 300;
}
.youtubeevent .col-left .lbltxt {
  display: inline-block;
  line-height: 34px;
}
.youtubeevent .col-left .error {
  margin-top: 5px;
  clear: both;
}
.youtubeevent .col-left .notesTxt {
  font-size: 13px;
  line-height: 1.1429rem;
  display: block;
  margin: 10px 0;
}
.youtubeevent .btn-row {
  border-top: 1px solid #e2e7eb;
  padding: 20px 30px 20px;
  text-align: right;
  clear: both;
}
.youtubeevent h5 {
  width: 100%;
  float: left;
  font-size: 1.28571rem;
  font-weight: 500;
  color: #000;
  margin: 10px 0 10px;
}
.youtubeevent .plus-minus {
  padding: 2px 7px;
  border-radius: 0px;
  border: none;
}
.youtubeevent .btn-default.plus-minus:focus,
.youtubeevent .btn-default.plus-minus:active,
.youtubeevent .btn-default.plus-minus:hover {
  background-color: transparent !important;
  box-shadow: none;
}
.youtubeevent .plus-minus.collapsed span:before {
  content: '\f078';
}
.youtubeevent .plus-minus span:before {
  font-family: FontAwesome;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  content: '\f077';
}
.youtubeevent .bitrate {
  position: relative;
}
.youtubeevent .framerate {
  position: relative;
}
.youtubeevent .col-lg-9 {
  position: inherit;
}
.youtubeevent .profile-settings .error {
  position: absolute;
  top: 0.857143rem;
  left: 230px;
  width: 300px;
}
.youtubeevent .bitrate-input {
  width: 200px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
}
.youtubeevent .bitrate-input input {
  width: 240px;
  border: none;
}
.youtubeevent .profile-select-invalid button {
  border-left: 2px solid #e24148;
}
.youtubeevent .profile-select button {
  border-left: 2px solid #25c93b;
}
.youtubeevent .profile-settings .error {
  position: relative;
  top: 5px;
  left: 0px;
}
.youtubeevent .help-btn {
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  line-height: 24px;
  font-size: 15px;
  background: #337ab7;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
}
.youtubeevent .reset {
  position: relative;
  min-height: 0px !important;
}
.youtubeevent .upBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.youtubeevent .downBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 17px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.youtubeevent .upBtn:focus,
.youtubeevent .downBtn:focus {
  border: none;
  outline: none;
}
.youtubeevent .page-title h5 {
  width: 100%;
  float: left;
  font-size: 1.28571rem;
  font-weight: 300;
  color: #000;
  margin: 10px 0 10px;
}
.youtubeevent .scheduleddate {
  max-width: 520px;
}
.youtubeevent .tab-content {
  padding: 15px;
  border-top: 1px solid #e44920;
}
.youtubeevent .edit-content {
  padding: 15px;
  border-top: 1px solid #e44920;
}
.youtubeevent .nav-tabs {
  border-bottom: none;
}
.youtubeevent .form-group .notesTxt {
  margin-top: 10px;
  display: block;
  font-size: 13px;
  font-style: italic;
}
.youtubeevent .form-group .notesTxt i {
  color: #ffca00;
}
.youtubeevent .infolink {
  color: #fff;
  background: #e44920;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 1rem;
  margin-left: 5px;
  font-family: 'Times New Roman';
  font-style: italic;
  font-weight: bold;
  font-size: 1.28571rem;
  line-height: 20px;
  text-decoration: none;
}
.youtubeevent .stream-multi-select {
  float: left;
  margin-right: 10px;
}
.youtubeevent .ms-btn {
  margin-top: 10px;
  text-decoration: underline;
  font-size: 1rem;
  display: inline-block;
}
.youtubeevent .page-title .backbtn {
  margin-top: 10px;
}
.youtubeevent .page-title .subbtn {
  margin-top: 10px;
  margin-right: 10px;
}
.youtubeevent .customRadio {
  display: inline;
}
.youtubeevent .customRadio::after {
  top: 2px;
}
.youtubeevent .ytlive-form span.col-sm-3 {
  line-height: 36px;
}
.youtubeevent .ytlive-form .customRadio {
  display: inline-block;
  margin-right: 10px;
}
.youtubeevent .date-input .fa {
  position: absolute;
  right: 25px;
  top: 8px;
  pointer-events: none;
}
.youtubeevent .addNew {
  text-align: center;
  overflow: hidden;
  padding: 10px;
  margin: 15px 0 15px;
  font-size: 0.857143rem;
}
.youtubeevent .customRadio > span {
  display: inline-block !important;
  margin-right: 20px;
}
.youtubeevent .isnotlive {
  text-align: center;
  display: block;
  margin: 150px 0 150px;
}
.youtubeevent .form-group .notestreamTxt {
  padding-top: 15px !important;
  display: block;
  font-size: 13px;
  font-style: italic;
}
.youtubeevent .form-group .notestreamTxt i {
  color: #ffca00 !important;
}
.youtubeevent .profileImg {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.youtubeevent .profileImg img {
  max-width: 100%;
  vertical-align: top;
}

.youtubeevent .profileName {
  display: inline-block;
  margin: 7px 10px 0 0;
  vertical-align: top;
  font-weight: 500;
}
.yt-tooltip {
  position: relative;
  cursor: pointer;
}
.yt-tooltip .tooltiptext {
  visibility: hidden;
  width: 310px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 3;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  white-space: pre-wrap;
  font-family: 'Open Sans', sans-serif;
}
.yt-tooltip:hover .tooltiptext {
  visibility: visible;
}

.editYoutube .panel-body {
  padding: 50px 30px 50px;
}
.editYoutube .btn-row {
  border-top: 1px solid #e2e7eb;
  padding: 20px 30px 0px;
  text-align: center;
  clear: both;
}
.editYoutube .page-wrap {
  margin-bottom: 20px;
}
.editYoutube .notesTxt {
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 20px;
  text-align: justify;
}
.editYoutube .help-btn {
  display: inline-block;
  text-decoration: none;
  color: #e44920;
  margin-top: 9px;
  margin-left: 6px;
  cursor: pointer;
}
.editYoutube {
  background: #fff;
  position: relative;
  min-height: 700px;
}
.editYoutube .page-title .backbtn {
  margin-top: 10px;
}

/* youtube help*/

.youtubehelp .panel-body {
  padding: 0 30px 15px;
}
.youtubehelp .btn-row {
  border-top: 1px solid #e2e7eb;
  padding: 20px 30px 20px;
  text-align: center;
  clear: both;
}
.youtubehelp .help-block {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  color: #737373;
}
.youtubehelp .help-block p,
.youtubehelp .help-block strong {
  color: #737373;
}
.youtubehelp .help-block img {
  max-width: 100%;
}
.youtubehelp .page-title .backbtn {
  margin-top: 10px;
}

.youtubeevent .nav-tabs {
  border-bottom: none;
}
.youtubeevent .nav-tabs > li > a {
  color: #000;
  text-transform: uppercase;
  padding: 10px 13px;
  border: none;
  border-bottom: 3px solid transparent;
  position: relative;
  bottom: -1px;
  text-align: center;
  font-size: 1.1429rem;
  font-weight: 300;
}
.youtubeevent .nav-tabs > li > a b {
  font-weight: normal;
}
.youtubeevent .nav-tabs > li > a span {
  padding-right: 5px;
  display: inline-block;
}
.youtubeevent .nav-tabs > li > a:hover {
  color: #000;
  border: none;
  border-bottom: 3px solid #e44920;
  background: transparent;
}
.youtubeevent .nav-tabs > li > a.active,
.youtubeevent .nav-tabs > li > a.active:focus,
.youtubeevent .nav-tabs > li > a.active:hover {
  color: #000;
  cursor: default;
  border: none;
  border-bottom: 3px solid #e44920;
  background: transparent;
}
.youtubeevent .nav-tabs > li.disabled > a,
.youtubeevent .nav-tabs > li.disabled > a:focus,
.youtubeevent .nav-tabs > li.disabled > a:hover {
  color: #777;
  cursor: no-drop;
}
.youtubeevent .nav-tabs > li.disabled > a:hover {
  border-bottom: 3px solid transparent !important;
}
.youtubeevent .nav-tabs > li.disabled > a:focus {
  pointer-events: none;
  color: #777;
}
.youtubeevent .ant-calendar-picker {
  width: 100%;
}

.preview-overlay{
  /* position: absolute;
  top: 0; */

}
.videobox{width: 100%;}
@media (max-width: 767px) {
  .youtubeevent .customRadio {
    margin-bottom: 6px;
  }
}
