.outerBox {
  position: relative;
  width: 100%;
  height: var(--outer-box-height);
  border: 2px dashed gray;
}

.innerBox {
  position: absolute;
  left: 10px;
  top: 10px;
  width: var(--inner-box-width);
  height: var(--inner-box-height);
  background-color: transparent;
  border: 1px solid gray;
}

.information {
  font-weight: bold;
  padding: 3px;
  margin-right: 20px;
}

.information-row {
  margin-top: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-saparator {
  margin: 0px 2px;
  display: inline;
}

.lstcontainer {
  display: flex;
  margin-top: 10px;
}

.fallback-slate-info-container {
  margin-top: 15px;
}

.box {
  border: 1px solid #d9d9d9;
  padding: 5px 15px;
  text-align: center;
  display: inline;
  color: gray;
  border-radius: 4px;
}

.logo {
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  object-fit: contain;
}

.previewToolMain {
  padding: 20px 30px 30px;
}

.previewToolMain .page-title {
  padding: 0;
}

.previewToolMain .ant-upload-list-item-card-actions .anticon,
.previewToolMain .ant-btn .anticon {
  vertical-align: middle;
}

.previewToolMain .ant-slider-vertical {
  height: 259px;
  /* height: 286px; */
  margin-top: 0;
}

.resizer {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid black;
  cursor: se-resize;
}
.image-loader {
  width: 150px;
  height: 70px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attr-label {
  font-weight: bold;
  font-size: 14px;
}
/* .st-video-bitrate {
}
.st-profile-dropdown {
  margin-bottom: 4px !important;
}
.st-profile-dropdown .ant-form-item-control {
  line-height: 1.5 !important;
}
.st-profile {
}
.st-profile .ant-form-item {
  margin-bottom: 0;
}
.st-profile .btn-row {
  text-align: right;
  clear: both;
}

.st-profile .st-video-bitrate {
  width: 100%;
  overflow: hidden;
}

.st-profile .st-video-bitrate input {
  border: none;
} */

@media (max-width: 767px) {
  .previewToolMain {
    padding: 1rem 1em 1.5rem;
  }

  .previewToolMain .ant-slider-vertical {
    height: 229px;
    /* height: 255px; */
    margin-top: 0;
  }

  .previewToolMain .page-title {
    margin-bottom: 0.5rem;
  }

  /* .innerBox {
     width: 80px;
     height: 55px;
   } */

  .lstcontainer {
    flex-direction: column;
  }

  .information {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .topborder {
    border-top: none;
    border-radius: 0;
  }
}

/* .plus-minus:before {
  font-family: FontAwesome;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  content: '\f077';
} */

.antd-collapse > .ant-collapse-item {
  border: transparent !important;
}

.antd-collapse {
  background-color: transparent !important;
}
.antd-collapse .ant-collapse-content {
  overflow: visible !important;
}

.antd-collapse .sub-title {
  font-size: 1.28571rem;
  font-weight: 700;
  color: #f15a2d;
  line-height: 34px;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}
.antd-collapse .ant-collapse-header {
  display: inline-block;
  padding: 0 40px 0 0 !important;
}
