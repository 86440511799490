html {
  font-size: 14px;
}

@media (max-width: 767px) {
  html {
    font-size: 13px;
  }
}

.error {
  color: #ed4747;
  font-size: 13px !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

a {
  color: #1e76ac;
  font-weight: 400;
}

textarea {
  resize: none !important;
}

.red {
  color: red !important;
}

.form-control {
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-left-width: 2px;
  border-left-color: red !important;
}

.form-control.success {
  border-left-color: rgb(37, 201, 59) !important;
}

.select-form-control>div {
  border-left-width: 2px;
  border-left-color: red;
  border-radius: 5px;
}

.select-form-control.success>div {
  border-left-color: rgb(37, 201, 59) !important;
}

.btn-primary {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
  background-color: #e44920;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
}

.btn-primary:hover {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #e44920;
  border-color: #e44920;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f15a2d;
}

button:focus {
  outline: 0 !important;
}

.customCheckBox {
  position: relative;
  display: block;
  z-index: 0;
}

.customCheckBox [type='checkbox'] {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  cursor: pointer;
}

.customCheckBox [type='checkbox']+span .customCheckBoxbutton [type='checkbox']+span {
  display: inline-block;
  position: relative;
}

.customCheckBox [type='checkbox']+span:before {
  content: '';
  height: 20px;
  min-width: 20px;
  width: 20px;
  border: 2px solid #b9c7d6;
  display: inline-flex;
  vertical-align: middle;
  margin-right: 10px;
}

.customCheckBox [type='checkbox']:checked+span:after {
  content: '';
  height: 6px;
  width: 0.857143rem;
  border-left: 2px solid #e34829;
  display: inline-flex;
  position: absolute;
  border-bottom: 2px solid #e34829;
  top: 7px;
  transform: rotate(-45deg);
  left: 4px;
}

.customCheckBoxbutton {
  display: inline-block;
  position: relative;
}

.customCheckBoxbutton [type='checkbox'] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.customCheckBoxbutton [type='checkbox']+span {
  padding: 3px 0.857143rem;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e44920;
  color: #fff;
}

.customCheckBoxbutton [type='checkbox']:checked+span {
  background-color: #000000;
}

.btn {
  display: inline-block;
  padding: 6px 0.857143rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn.btn-md {
  padding: 10px 15px;
  font-size: 1.1429rem;
}

.btn.btn-lg {
  padding: 10px 15px;
  font-size: 1.1429rem;
}

.customRadio {
  position: relative;
  display: block;
}

.customRadio input[type='radio'] {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customRadio>span {
  display: block;
  padding-left: 25px;
  position: relative;
}

.customRadio>span:after,
.customRadio>span:before {
  position: absolute;
  content: '';
}

.customRadio>span:after {
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.customRadio>span:before {
  width: 10px;
  height: 10px;
  background: #e44920;
  border-radius: 50%;
  left: 5px;
  top: 5px;
  opacity: 0;
}

.customRadio [type='radio']:checked+span:before {
  opacity: 1;
}

.customRadio [type='radio']:checked:disabled+span:before {
  background: #00000060;
}

.customRadio [type='radio']:disabled {
  cursor: not-allowed;
}

.page-title {
  margin-bottom: 20px;
  padding: 10px 30px 0;
  overflow: hidden;
}

.info-text {
  color: #999;
}

.f-size-16 {
  font-size: 1.1429rem;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 1rem;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 0.857143rem;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.pagewrap>.container {
  max-width: 100% !important;
  padding: 0;
}

.multiSelect {
  max-width: 200px !important;
}

button.btag {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #337ab7;
  /*text-decoration: underline;*/
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
}

button.btag:hover {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  /*text-decoration: underline;*/
  cursor: pointer;
}

.size16 {
  font-size: 1.1429rem !important;
}

button.atag {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #1890ff;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
}

button.atag:hover {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.navsection .rightNav li.nav-item>a {
  text-transform: capitalize !important;
}

.modal-body ul {
  list-style: none;
  padding: 0px;
}

.modal-body ul li {
  padding: 8px 5px;
  padding-right: 150px;
  position: relative;
}

.modal-body ul li .modalList {
  position: absolute;
  right: 5px;
  top: 9px;
}

.modal-body ul li .modalList .btn {
  margin-left: 5px;
}

.modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.fa-lg {
  font-size: 1.33333333em !important;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em !important;
}

.fa-3x {
  font-size: 3em !important;
}

.fa-4x {
  font-size: 4em !important;
}

.fa-5x {
  font-size: 5em !important;
}

.text-info {
  color: #31708f !important;
}

.page-wrap .nav-tabs {
  border-bottom: none;
  margin: 0 30px;
}

.nav-tabs>li>a.nav-link {
  color: #000;
  text-transform: uppercase;
  padding: 10px 13px;
  border: none;
  border-bottom: 3px solid transparent;
  position: relative;
  bottom: -1px;
  text-align: center;
  font-size: 1.1429rem;
  font-weight: 300;
  display: inline-block;
}

.nav-tabs>li>a.active.nav-link,
.nav-tabs>li>a.active.nav-link:focus,
.nav-tabs>li>a.active.nav-link:hover {
  color: #000;
  cursor: default;
  border: none;
  border-bottom: 3px solid #e44920;
  background: transparent;
}

.profile-settings {
  padding: 0 30px;
  font-size: 1.1429rem;
  font-weight: 300;
}

.profile-settings .col-lg-2 {
  display: inline-block;
  margin-bottom: 5px;
  min-width: 175px;
}

.profile-settings .customCheckBox {
  margin-bottom: 3px;
}

.nav-wrap {
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 2px;
}

.page-wrap .nav-tabs {
  border-bottom: none;
  margin: 0 15px;
  display: flex;
  flex-wrap: nowrap;
}

.nav-wrap::-webkit-scrollbar {
  height: 0;
}

@media (max-width: 767px) {
  .nav-wrap {
    overflow-y: auto;
  }
}

.link {
  color: #337ab7;
  cursor: pointer;
}

/* .css-2b097c-container{z-index: 2;} */
.modal-footer .btn.btn-md {
  padding: 5px 15px;
}

.two-line-ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
}

@supports (-webkit-line-clamp: 2) {
  .two-line-ellipsis {
    white-space: initial !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media (max-width: 767px) {
  .page-title h4 {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .page-title {
    padding: 10px 1rem 0;
    margin-bottom: 1rem;
  }

  .tab-content {
    padding: 30px 20px !important;
  }

  .profile-settings {
    padding: 0 20px !important;
  }

  .profile-settings .bitrate-input {
    width: 100% !important;
  }

  .profile-settings .bitrate-input input {
    width: 100% !important;
  }

  /* .nav-wrap {}
    .nav-wrap .nav-tabs>li {} */
  .nav-tabs>li>a {
    padding: 10px 15px;
  }
}

@media (max-width: 575px) {
  .page-title h4 {
    width: 100%;
  }
}

.rc-anchor-normal div.rc-anchor-content {
  height: 70px;
}

.refresh {
  animation: refresh 1.5s infinite linear;
}

@keyframes refresh {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-input-password {
  height: calc(1.5em + 0.75rem + 3px);
}

.ant-input-password .ant-input {
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.smallContainer {
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.imageSkeleton .ant-skeleton-title {
  height: 130px;
}

.skeleton_card.imageSkeleton .ant-skeleton-title {
  min-height: 220px;
}

.paraskeleton .ant-skeleton-title {
  margin-top: 0;
}

.payskeleton .ant-skeleton-title {
  min-height: 344px;
}

.buttonskeleton {
  width: auto;
}

.buttonskeleton .ant-skeleton-title {
  width: 56px;
  height: 25px;
  margin-top: 0;
}

.invoiceSkeleton .ant-skeleton-title {
  height: 60px;
}

.tableskeleton1 .ant-skeleton-title {
  min-height: 180px;
  margin: 15px 0;
  width: auto;
}

.analyticTable {
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.analyticTable .row:not(:last-child) .col-4 {
  border-bottom: 1px solid #eee;
}

.analyticTable .col-4:not(:last-child) {
  border-right: 1px solid #eee;
}

.analyticTable .col-4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.analyticTable .col-4:first-child {
  max-width: 30%;
  flex: 0 0 30%;
}

.analyticTable .col-4:nth-child(2) {
  max-width: 36%;
  flex: 0 0 36%;
}

.analyticTable .col-4:last-child {
  max-width: 34%;
  flex: 0 0 34%;
}

.fbTable .col-4:first-child {
  max-width: 25%;
  flex: 0 0 25%;
}

.fbTable .col-4:nth-child(2) {
  max-width: 42%;
  flex: 0 0 42%;
}

.fbTable .col-4:last-child {
  max-width: 33%;
  flex: 0 0 33%;
}

.skeletonRight .ant-skeleton-title {
  float: right;
}

.ant-slider-handle {
  border-color: #e34829;
  margin-bottom: 0 !important;
}

.ant-slider-track {
  background-color: #e34829;
}

.ant-notification{
  z-index: 9999999999 !important;
}