.appVersion span {
  color: #b3b3b3;
  font-size: 0.857143rem;
  font-weight: 300;
}
.appVersion .shopLink {
  color: #b3b3b3;
  font-size: 0.857143rem;
  padding-left: 5px;
  font-weight: 300;
  background: none !important;
  border: none;
}
.appVersion .shopLink:hover {
  background: none !important;
  border: none;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
