/* facebookview */
.facebookstream .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -45px;
}
.facebookstream {
  position: relative;
}
.facebookstream .panel-body {
  margin: 0 auto;
}
.facebookstream .page-wrap {
  margin-bottom: 20px;
}
.facebookstream .form-group::after {
  clear: both;
  display: table;
  content: ' ';
}
.facebookstream .upBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.facebookstream .downBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 17px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.facebookstream .upBtn:focus,
.facebookstream .downBtn:focus {
  border: none;
  outline: none;
}
.facebookstream .bitrate {
  position: relative;
}
.facebookstream .framerate {
  position: relative;
}
.facebookstream .profile-settings .error {
  position: absolute;
  top: 0;
  left: 210px;
  width: 300px;
  margin-top: 0 !important;
  line-height: 1.3;
}
.profileImg {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.profileImg img {
  max-width: 100%;
  vertical-align: top;
}
.facebookstream .profileName {
  display: inline-block;
  margin: 7px 10px 0 0;
  vertical-align: top;
  font-weight: 500;
}
@media (max-width: 767px) {
  .facebookstream .profile-settings .error {
    position: static;
    left: 0px;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 8px !important;
  }
}
.facebookstream .bitrate-input {
  width: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 0px;
  position: relative;
}
.facebookstream .bitrate-input input {
  width: 240px;
  border: none;
}
.facebookstream .profile-select-invalid button {
  border-left: 2px solid #e24148;
}
.facebookstream .profile-select button {
  border-left: 2px solid #25c93b;
}
.facebookstream .multiSelect > button {
  min-width: 230px;
}
.facebookstream .form-group .notesTxt {
  margin-top: 10px;
  display: block;
  font-size: 13px;
  font-style: italic;
}
.facebookstream .txtlabel {
  line-height: 34px;
  vertical-align: top;
}
.facebookstream .txtlabelBig {
  line-height: 50px;
  vertical-align: top;
}
.facebookstream .tab-content {
  border-top: 1px solid #ddd;
  padding: 30px 30px 0px 30px;
  font-size: 1.1429rem;
  font-weight: 300;
}
.facebookstream .tab-content .customCheckBox {
  margin-top: 0px;
}

.facebookstream .tab-content #postcontrol .customCheckBox {
  margin-top: 2px;
}
.facebookstream .nocrosspost {
  font-size: 13px;
  font-weight: 400;
}
.facebookstream .col-sm-6 {
  font-size: 1.1429rem;
  font-weight: 300;
}
.facebookstream .col-sm-6 .lbltxt {
  display: inline-block;
  line-height: 34px;
}
.facebookstream .col-sm-6 .infolink1 {
  color: #fff;
  background: #e44920;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 1rem;
  font-family: 'Times New Roman';
  font-style: italic;
  font-weight: bold;
  font-size: 1.28571rem;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}
.facebookstream .lbltxt .fb-tooltip {
  position: relative;
  cursor: pointer;
}
.facebookstream .lbltxt .fb-tooltip .tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 3;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  white-space: pre-wrap;
  font-family: 'Open Sans', sans-serif;
}
.facebookstream .lbltxt .fb-tooltip:hover .tooltiptext {
  visibility: visible;
}
.facebookstream .user-tooltip {
  position: relative;
  cursor: pointer;
}
.facebookstream .user-tooltip .tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 3;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  white-space: pre-wrap;
  font-family: 'Open Sans', sans-serif;
}
.facebookstream .user-tooltip:hover .tooltiptext {
  visibility: visible;
}
.facebookstream .customRadio::after {
  top: 0px !important;
}
.facebookstream .col-sm-6 .error {
  margin-top: 5px;
}
.facebookstream .col-sm-6 .notesTxt {
  font-size: 13px;
  line-height: 1.1429rem;
  display: block;
  margin: 10px 0;
}
.facebookstream .nav-tabs {
  border-bottom: none;
}
.facebookstream .scheduleddate {
  max-width: 520px;
  margin: 10px 0px;
}
.facebookstream .btn-row {
  border-top: 1px solid #e2e7eb;
  padding: 20px 30px 0px;
  text-align: right;
  clear: both;
}
.facebookstream .angucomplete-dropdown {
  height: 190px;
  overflow: auto;
}
.facebookstream .fbnotesTxt {
  font-size: 13px;
  line-height: 1.1429rem;
  display: block;
  margin: 10px 0;
}
.facebookstream .fa-times-circle {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.facebookstream .fa-times-circle a.text-info:focus,
a.text-info:hover {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.facebookstream .crosspost .fb-tooltip {
  position: relative;
  cursor: pointer;
}
.facebookstream .crosspost .fb-tooltip .tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 3;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  white-space: pre-wrap;
  font-family: 'Open Sans', sans-serif;
}
.facebookstream .crosspost .fb-tooltip:hover .tooltiptext {
  visibility: visible;
}

/*loading bar css override*/
#loading-bar .bar {
  background: #e44920 !important;
}
/* Fancy blur effect */
#loading-bar .peg {
  -moz-box-shadow: #e44920 1px 0 6px 1px !important;
  -ms-box-shadow: #e44920 1px 0 6px 1px !important;
  -webkit-box-shadow: #e44920 1px 0 6px 1px !important;
  box-shadow: #e44920 1px 0 6px 1px !important;
}
#loading-bar-spinner {
  display: none !important;
}

.facebookstream .reset {
  position: relative;
  min-height: 0px !important;
}
.facebookstream .plus-minus span:before {
  font-family: FontAwesome;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  content: '\f077';
}
.facebookstream .page-title h5 {
  width: 100%;
  float: left;
  font-size: 1.28571rem;
  font-weight: 300;
  color: #000;
  margin: 10px 0 10px;
}
.facebookstream .plus-minus {
  padding: 2px 7px;
  border-radius: 0px;
  border: none;
}
.facebookstream .btn-default.plus-minus:focus,
.facebookstream .btn-default.plus-minus:active,
.facebookstream .btn-default.plus-minus:hover {
  background-color: transparent !important;
  box-shadow: none;
}
.facebookstream .plus-minus.collapsed span:before {
  content: '\f078';
}
.facebookstream .custom {
  padding: 5px 10px;
  background: #e44920;
  border: 1px solid #e44920;
  position: relative;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
  margin-bottom: 5px;
}
.facebookstream .custom .custom_image {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  /* height: 100%; */
  font-size: 24px;
  /* width: 100%; */
}
.facebookstream .pageWelcomeTxt {
  background: #f3f3f3;
  padding: 20px 30px 20px 50px;
  font-size: 1.28571rem;
  line-height: 30px;
  color: #000;
  font-weight: 300;
}
.facebookstream .pageWelcomeTxt::before {
  background: #f15a2d;
  content: '';
  width: 8px;
  height: 8px;
  left: 30px;
  position: absolute;
  margin: 10px 15px 0 0;
}
.facebookstream .image_name {
  float: left;
  margin: 10px 0 10px;
  font-size: 1rem;
}

.facebookstream .page-title .subbtn {
  margin-top: 10px;
  margin-right: 10px;
}
/* .facebookstream .fb-basics {margin-left:-12px;} */
.facebookstream .fa-times {
  cursor: pointer;
}
.facebookstream .sponsorInfo span {
  display: inline-block;
}
.facebookstream .sponsorInfo {
  position: absolute;
  text-align: right;
  top: 5px;
  right: 25px;
  font-size: 15px;
  font-weight: 500;
}
.facebookstream .blue_verified {
  color: #fff;
  background: #5890ff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: 0.857143rem;
  line-height: 21px;
}
.facebookstream .gray_verified {
  color: #fff;
  background: #9b9fab;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: 0.857143rem;
  line-height: 21px;
}
.facebookstream .date-input .fa {
  position: absolute;
  right: 25px;
  top: 8px;
  pointer-events: none;
}
.facebookstream .sponsorInfo {
  top: 40px;
}
.facebookstream .multiSelect > button {
  margin-top: 5px;
}
.friendImg img {
  height: 20px;
  width: 20px;
}

/* .facebookstream .page-title h4 {
  max-width: 100%;
  width: 100%;
} */

.facebookstream .page-title .subbtn {
  margin-top: 00px;
  margin-right: 10px;
}

.facebookstream .page-title .backbtn {
  margin-top: 10px;
}

.facebookstream .locationRow .dropdown img {
  width: 0.857143rem;
  margin-right: 5px;
}
.facebookstream .locationRow .locationWrap {
  padding: 0 0px;
}
.facebookstream .locationRow .locationWrap .dropdown-menu {
  min-width: 176px;
  margin: 0px;
  padding: 0px;
}
.facebookstream .locationRow .locationWrap .dropdown-menu .divider {
  margin: 0px;
}
.facebookstream .locationRow .dropdown-toggle {
  display: block;
  color: #333;
  text-decoration: none;
}
.facebookstream .locationRow .dropdown-menu > li > a {
  padding: 6px 20px;
}
.facebookstream .locationRow .col-lg-2.col-sm-3 {
  padding: 0px !important;
}
.facebookstream .locationRow .col-lg-10.col-sm-9 {
  padding: 0px !important;
}
.facebookstream .locationRow .addLocationsInput {
  width: 100%;
  border: none;
  padding: 6px 10px;
}
.facebookstream .locationRow .locationDiv {
  padding: 0 15px;
}
.facebookstream .locationRow .dropdown {
  padding: 6px 10px;
  border-right: 1px solid #ccc;
}

.facebookstream .help-btn {
  line-height: 1.28571rem;
  font-size: 11px;
  background: #337ab7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 0;
}

.facebookstream .btn-default.plus-minus:active,
.facebookstream .btn-default.plus-minus:hover {
  background-color: transparent !important;
  box-shadow: none;
}
.facebookstream .info-btn {
  display: inline-block;
  margin-top: 2px;
  font-size: 10px;
  background: #bbb;
  border-radius: 50%;
  width: 0.857143rem;
  height: 0.857143rem;
  color: #fff;
  text-align: center;
}

.locationRow .form-group .true {
  background: #4267b2;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: inline-block;
  text-align: center;
  line-height: 1.28571rem;
  margin-right: 10px;
  color: #fff;
  font-size: 11px;
}
.locationRow .form-group .false {
  background: red;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: inline-block;
  text-align: center;
  line-height: 1.28571rem;
  margin-right: 10px;
  color: #fff;
  font-size: 11px;
}
.facebookstream .tab-content .genderValue label {
  margin-right: 10px;
  font-weight: 300;
}
.facebookstream .tab-content .tab-pane {
  /* padding-left: 15px;  */
}
.facebookstream .tab-content .ageValue span {
  display: inline-block;
  margin: 0 5px;
}
.facebookstream .highlight {
  color: #ff0000;
}
/* .facebookstream .nav-wrap{
    padding-left: 30px;
    padding-right: 30px;
} */
/* .facebookstream .nav-tabs .nav-link{
    border: 0;
    border-bottom: 3px solid transparent;
    font-size: 1.1429rem;
    font-weight: 300;
}
.facebookstream .nav-tabs .nav-link:focus, 
.facebookstream .nav-tabs .nav-link:hover,
.facebookstream .nav-tabs .nav-link.active{
    border-bottom-color: #e44920;
} */
.ant-calendar-picker .ant-calendar-picker-input {
  height: 38px;
}
.facebookstream .tab-content .tab-pane .ant-table-thead > tr > th {
  font-weight: 700;
}
.facebookstream
  .tab-content
  .tab-pane
  .ant-table-thead
  > tr:first-child
  > th:first-child {
  width: 15%;
}
.crosspostingTable table {
  border: 1px solid #e8e8e8;
}
.crosspostingTable .ant-table-thead > tr > th,
.crosspostingTable .ant-table-tbody > tr > td {
  padding: 8px 1.1429rem;
}
.crosspostingTable .ant-table-tbody > tr:last-child td {
  border-bottom: 0;
}
.groupsByPageCheck > span {
  display: flex;
}
.customCheckBox.groupsByPageCheck [type='checkbox'] + span:before {
  min-width: 20px;
}
.customCheckBox.groupsByPageCheck [type='checkbox']:checked + span:after {
  top: 6px;
}
.groupsByPageCheck > span > p {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .crosspostingTable {
    margin-top: 10px;
  }
  #facebookstream .ant-select {
    width: 100% !important;
  }
}
.ant-calendar-time .ant-calendar-panel .ant-calendar-input-wrap {
  display: none;
}
