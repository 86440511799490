.new-user {
  padding: 20px 0 30px;
  border-bottom: 1px solid #c2c8d1;
}
.new-user .atag {
  color: rgb(30, 118, 172);
  font-weight: 500;
}
.entrySection .new-user a {
  font-size: 1.1429rem;
  color: #1e76ac;
}

.welcome h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.loginAppVersion {
  text-align: right;
  padding: 0 5px;
  padding-top: 15px;
  color: #b3b3b3;
  font-size: 0.857143rem;
  font-weight: 300;
}
.entryContainer .lang-dropdown:hover svg path {
  fill: white;
}
.login-links .forgotpass_link_blk button,
.login-links .help_link_blk a {
  color: rgb(30, 118, 172);
  font-weight: 400;
}
.login-links .help_link_blk a:hover {
  text-decoration: underline;
}
.leftSide .new-user .btag {
  font-size: 1.1429rem;
  color: rgb(30, 118, 172);
  font-weight: 400;
}
.regInput.login-links-register {
}
.regInput.login-links-register button {
  font-size: 1.28571rem;
  line-height: 1;
}
/* .loginInputBtn .btn-primary {font-size: 1.28571rem;} */
.loginInputBtn button.btn-primary {
  font-size: 15px;
}
.needsolo-link a {
  color: rgb(30, 118, 172);
}
.needsolo-link a:hover {
  text-decoration: underline;
  color: rgb(30, 118, 172);
}
.loginInput .input-group-addon i {
  margin: 12px 0px 0px -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #6c757d;
}
@media (max-width: 1300px) {
  .loginLang .dropdown-menu {
    right: 35px !important;
    left: auto !important;
    transform: translate3d(0px, 25px, 0px) !important;
  }
}
@media (max-width: 991px) {
  .entrySection {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 768px) {
  .entrySection {
    padding: 42px 5px 10px !important;
  }
  .regInput.login-links-register {
    margin-bottom: 0 !important;
  }
  .loginLang .dropdown-menu {
    right: 30px !important;
    left: auto !important;
  }
}
@media (max-width: 767px) {
  .entrySection .leftSide {
    padding: 20px 20px 15px;
  }
  .loginInputBtn button.btn-primary {
    padding: 10px;
    font-size: 1rem;
  }
}