/* .customCheckBox { position:relative; padding-left:25px;}
.customCheckBox input { position: absolute; z-index: 1; left: 0px; top: 0px; width: 21px; height: 21px; margin: 0; opacity: 0; }
.customCheckBox::after { position: absolute; left: 0px; top:0px; content: ""; width: 21px; height: 21px; background: url(../../assets/images/boxcast.png); }
.customCheckBox.selected::after { background-position:0 -30px; } */

.profile-settings .custDropdown .custDropdown__control {
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-left-width: 2px;
  background: #fff;
  height: 31px;
}
.profile-settings .custDropdown__indicator-separator {
  display: none;
}
@media (max-width: 575px) {
  .profileCheckBox {
   float: right;
   margin-right: -10px;
  }
}
