.csConnect {
  padding-top: 15px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.csConnect .sub-title {
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  margin: 0 0 10px;
  background: #fff;
  border-bottom: 1px solid #cadae9;
  text-transform: uppercase;
}
.csConnect .sub-title h4 {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.confSoloConnect_blk {
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 20px;
}
.serialNumber-upperCase {
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  .confSoloConnect_blk {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .csConnect {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cscFindBtn_blk {
    text-align: center;
  }
  .csConnect .container-min .container {
    padding-left: 0;
    padding-right: 0;
  }
}
