/* .solounit-main .solounit-contain .offline {
    opacity: 0.5;
} */
.unit-lists {
  max-width: 180px;
}
.unit-lists-wrap {
  overflow: hidden;
}
.solounit-contain .unit-wrap {
  max-height: 570px;
  overflow: scroll;
  margin-right: -20px;
  /* padding-bottom: 20px; */
  overflow-x: hidden;
}
.svg_plus path {
  fill: #b9c7d5;
}
.addSolo {
  height: 140px;
}
.addSolo a {
  font-size: 1.1429rem;
  color: #000;
  text-decoration: none;
  position: relative;
  height: 100%;
  box-shadow: 1px 0px 6px #d0d0d0;
}
@media (max-width: 575px) {
  .unit-item {
    cursor: pointer;
  }

  .unit-lists {
    position: absolute;
    left: -100%;
    z-index: 1;
    height: 100%;
    max-width: 85%;
    background: #fff;
    z-index: 3;
    transition: all 0.6s;
    margin-top: 48px;
  }
  .unit-lists.open {
    left: 0 !important;
  }

  .unit-lists-wrap {
    background: #fff;
    position: relative;
    z-index: 1;
  }
  .solounit-contain .unit-wrap {
    margin: 0;
    max-height: 61vh;
  }

  .solounit-contain {
    position: relative;
    z-index: 3;
  }
  .unit-lists.open:after {
    background: rgba(0, 0, 0, 0.5);
    content: '';
    position: absolute;
    width: 150%;
    height: 100%;
    /* display: none; */
  }
  .solounit-contain .active .unit-lists:after {
    display: block;
  }
  .solounit-contain .active .unit-lists {
    left: 0;
  }
  /* .unit-lists:before {
        content: "";
        width: 300%;
        height: 100%;
        left: 0;
        background: rgb(0 0 0 / 45%);
        z-index: -2;
        position: absolute;
    } */
}
