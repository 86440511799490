.Entry {
  padding-top: 0px;
  padding-bottom: 0px;
  background: url('./assets/images/login-bg.jpg') no-repeat top center #000;
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
  position: relative;
}
.entrySection {
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
}

.entryContainer {
  border: none;
  font-size: 15px;
  width: 926px;
  margin: auto auto;
  padding: 0 15px;
  position: relative;
}
.rowflex {
  background-color: rgba(163, 182, 197, 0.18);
  box-shadow: 11px 6px 46px #000000;
  margin-top: 20px;
}

.rightSide {
  background-color: rgba(255, 255, 255, 0.2);
}

.leftSide {
  background: #fff;
  min-height: 550px;
}
.entrySection .leftSide {
  padding: 30px 30px 15px 30px;
}

.titleTxt {
  font-size: 62px;
  font-weight: 300;
  text-align: left;
  line-height: 62px;
  width: 320px;
  margin: auto auto;
  height: 200px;
  color: #fff;
}
.entrySection .leftSide input.form-control {
  border-radius: 0;
  height: 44px;
  border-left-color: #e24148;
  border-left-width: 2px;
  box-shadow: none;
}
.entrySection .leftSide input:focus {
  border-color: #66afe9;
}
.entrySection .leftSide input.is-valid {
  border-left-color: rgb(37, 201, 59);
  border-left-width: 2px;
}
a {
  font-size: 1rem;
  font-weight: 300;
}
.form-group {
  margin-bottom: 15px;
}
