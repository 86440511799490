:root {
  --strength: 0%;
  --bgcolor: #4caf50;
}

.video-block {
  /* width: 100%; */
  margin-left: auto;
  object-fit: contain;
  /* border: 1px dashed gray; */
  height: var(--video-block-height, auto);
  position: relative;
}

.live-video {
  width: 100%;
  max-height: var(--video-block-height, auto);
  object-fit: contain;
  background-color: black;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
}

.video-overlay {
  position: relative;
  /* width: 25%;
  height: auto; */
}
.overlay-preview {
  position: absolute;
  transition: 0.5s ease;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #474444;
  opacity: 0.7;
}
.overlay-text {
  color: white;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 47%;
  left: 0%;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
}
.play-button-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ant-slider:hover .ant-slider-track {
  background-color: #e34829 !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border: 2px solid #e34829 !important;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #e34829 !important;
}

.ant-slider-handle:focus {
  border-color: #e34829 !important;
}

.blur {
  filter: blur(5px);
}
.audio-info-text {
  color: gray;
  font-size: 11px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .play-button-preview {
    top: 35%;
  }
}

.streamtools-settings-link {
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: underline;
  font-style: italic;
}

.main-container {
  margin-top: 20px;
}
.video-skeleton {
  width: 100%;
  min-height: var(--skeleton-block-height);
}
.custom-skeleton {
  width: 100%;
  background-color: red;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #f2f2f2),
    color-stop(37%, #e6e6e6),
    color-stop(63%, #f2f2f2)
  );
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.vumeter {
  padding: 20px;
}

.meter-container {
  width: 10px;
  height: 280px;
  background-color: #eee;
  margin: 3px auto;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
}

.meter {
  width: 100%;
  background-color: var(--bgcolor);
  transition: height 0.2s ease, background-color 0.5s ease;
  height: var(--strength);
}

@media (max-width: 767px) {
  .meter-container {
    height: 250px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e34829 !important;
  border-color: #e34829 !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #e34829 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #e34829 !important;
}

.play-text {
  background-color: black;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 25px;
}

.play-txt {
  left: -10%;
  color: white;
  font-size: 1.2em;
  background-color: black;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  width: max-content;
}

.mute-link {
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 20px;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.8;
}

.mute-link:hover{
  opacity: 0.6;
}
