.loginLang a.lang-dropdown {
  color: #fff;
  width: auto;
  display: inline-block;
  text-transform: capitalize;
}
.loginLang .dropdown-menu {
  min-width: 190px;
  right: -160px;
  margin-top: 7px;
  left: inherit;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.fa {
  display: inline-block;
  font: normal normal normal 1rem/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-menu .fa-check:before {
  content: '\f00c';
}

.loginLang {
  position: absolute;
  right: -43px;
  top: -16px;
  cursor: pointer;
}
.lang-dropdown svg path {
  transition: all 0.6s;
  fill: #fff;
}
/* .lang-dropdown:hover svg path{
    fill:red;
} */
