/* .welcome h4 {
    color: #000;
    margin: 10px 0px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
} */
#wrapper.login {
  width: 100%;
  margin: 0 auto;
}
.forgot-page .welcome h4 {
  margin: 0px 0px 15px 0px;
}
.forgot-page .whitebg {
  min-height: 634px;
}

.login .container {
  max-width: 1170px;
  color: #fff;
  font-size: 15px;
  min-height: inherit;
}
.login .container .success-register {
  color: #000;
  margin-top: 60px;
}
.login .welcome {
  padding-left: 0px;
}
.login .navbar {
  background: #103482;
}
.login footer {
  clear: both;
  position: fixed;
  bottom: 0px;
  left: 0px;
}
.login fieldset {
  position: relative;
}
.login .loader {
  top: 180px;
  right: 0;
  width: auto;
  padding: 0.857143rem;
  color: #000;
}
.login .error {
  width: 100%;
  margin: 10px 0 0 0;
  display: block;
}
.login .success {
  position: absolute;
  top: 10px;
  left: 295px;
  color: #fff;
  background: green;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
}
.login .btn {
  word-wrap: break-word;
  white-space: normal;
}
.login .loginInput input,
.login .pswInput input {
  padding-right: 30px;
}
.login .loginInput::after {
  content: '';
  height: 44px;
  width: 30px;
  position: absolute;
  right: 5px;
  top: 0px;
  background: url('../../assets/images/mail-icon.png') no-repeat right center;
}
.login .pswInput::after {
  content: '';
  height: 44px;
  width: 30px;
  position: absolute;
  right: 5px;
  top: 0px;
  background: url('../../assets/images/password-icon.png') no-repeat right
    center;
}
.login .btm-link {
  text-align: right;
  padding: 0 5px;
}
.login .btm-link span {
  color: #b3b3b3;
  font-size: 0.857143rem;
  font-weight: 300;
}
.login .btm-link a {
  color: #b3b3b3;
  font-size: 0.857143rem;
  padding-left: 5px;
  font-weight: 300;
}
.login .form-control {
  height: 44px;
  border-radius: 0px;
  padding-right: 40px;
}
.login .form-group {
  position: relative;
}

.forgotpassword .container {
  max-width: 1170px;
  color: #000;
  font-size: 15px;
}
.forgotpassword {
  position: relative;
  min-height: 400px;
  background: #fff;
  min-height: 100vh;
}
.forgotpassword .navbar {
  background: #103482;
}
.forgotpassword footer {
  clear: both;
  position: fixed;
  bottom: 0px;
  left: 0px;
}
.forgotpassword fieldset {
  position: relative;
}
.forgotpassword .loader {
  top: 180px;
  right: 0;
  width: auto;
  padding: 0.857143rem;
  color: #000;
}
.forgotpassword .success {
  position: absolute;
  top: 8px;
  left: 320px;
  color: #fff;
  background: green;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
}
.forgotpassword .error {
  position: absolute;
  top: 0.857143rem;
  left: 320px;
  width: 300px;
}
.forgotpassword-links {
  color: #000;
  margin: 40px 0px;
  font-size: 1.28571rem;
  font-weight: 300;
}
.forgotpassword-links a {
  color: #e44920;
}
.forgotpassword .form-control {
  height: 44px;
}
/* .forgot-page .welcome h4 { margin:0px 0px 15px 0px;} */
/* .forgot-page .whitebg { min-height: 634px;} */
.forgotpassword-panel {
  border: none;
  font-size: 15px;
  max-width: 520px;
  margin: 0 auto 0 240px;
}
.forgotpassword-panel label {
  font-weight: normal;
}
.forgotpassword-panel .errormsg {
  color: #a94442;
  margin: 10px 20px 0;
  font-size: 1rem;
}
.forgotpassword-panel .card-body {
  padding: 10px 20px;
}
.forgotpassword-panel .panel-heading {
  margin: 20px 20px 10px 20px;
  padding: 20px 0 0;
  border-bottom: 1px solid #103482;
}
.forgotpassword-panel .panel-heading h3 {
  border-bottom: 2px solid #103482;
  padding-bottom: 10px;
  font-size: 21px;
  color: #103482;
  font-weight: 600;
  display: inline-block;
  padding-right: 10px;
}
.forgotpassword .txtlabel {
  text-align: right;
  line-height: 44px;
}
#wrapper.forgotpassword {
  width: 100%;
  margin: 0 auto;
}
#wrapper.forgotpassword p {
  padding: 0 15px;
}
.entrySection .leftSide .welcome h4 {
  font-size: 20px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  line-height: 30px;
  margin: 0px 0px 10px;
}
