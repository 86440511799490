.solounit-main {
  background: #fff;
}

.sub-title {
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  margin: 0 0 10px;
  /* background: #fff; */
  text-transform: uppercase;
}

.sub-title h4 {
  font-size: 1.28571rem;
  font-weight: 700;
  color: #f15a2d;
  line-height: 34px;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}

.solounit-main .soloservices-details .block-wrap {
  position: relative;
}

.unitBlk_cont .block-wrap {
  min-height: 245px;
  border: 2px solid #b9c7d5;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 5px 5px 5px rgba(185, 199, 213, 0.4);
}

.unitBlk_cont .valuetxt {
  margin: 0;
  color: #000;
  font-size: 1rem;
  padding: 0px 0px 0px;
  font-weight: 700;
  display: inline-block;
}

.unitDestination .destTitleNdis .valuetxt {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 265px;
}

.page-title h4 {
  float: left;
  font-size: 2.5714rem;
  font-weight: 300;
  color: #000;
  margin: 0px;
  line-height: 50px;
}

.addNewSolo {
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 50px 30px;
  text-align: center;
  font-size: 21px;
  font-weight: 300;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addNewSolo a,
.addNewSolo a:not([href]):not([tabindex]) {
  color: #e34729;
  margin-right: 10px;
  text-decoration: underline;
  font-size: 1.28571rem;
  font-weight: 100;
}

.navOpen .unit-lists {
  left: 0 !important;
}


.navOpen .unit-item:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 8px solid #e44920;
}

.navOpen .unit-item.active {
  border-left: 3px solid #e44920;
}

.alert-info {
  border-radius: 0;
  width: auto;
  padding: 5px 15px;
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
  margin-bottom: 0 !important;
}

.alert-info strong {
  padding-right: 15px;
  font-weight: 300;
}

.alert-info .close {
  float: right;
  font-size: 15px;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

.solounit-main>.alert-warning {
  margin-bottom: 0;
  background-color: #e4491f38;
  border-color: #e4491f;
  /* border-radius: 0; */
  color: #d14e23;
  margin: 5px 8px;
}

.solounit-main>.alert-warning .fa {
  display: none;
}

@media (max-width: 480px) {
  .addNewSolo {
    flex-direction: column;
  }
}

.loaderWithText {
  min-height: 700px;
  max-height: 100vh;
}

.loaderWithText img {
  height: 57px;
}

.sketch {
  display: flex;
  flex-wrap: wrap;
}

.sketch-top {
  display: inline-block;
  width: 100%;
  max-width: 180px;
  /* width: calc(80% / 2); */
}

.sketch-body {
  display: inline-block;
  padding-left: 15px;
  width: calc(100% - 180px);
}

@media(min-width: 768px) {
  .unitBlk_cont {
    min-width: 340px;
  }
}

@media(max-width: 767px) {
  .unitBlk_cont {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sub-title h4{line-height: 1.2;}
  .sub-title {
    margin: 10px 0;
  }
}

@media(max-width:575px) {
  .sketch-body {
    width: 100%;
    padding-left: 0;
  }
  .navOpen .side-bg:after {
    background: rgba(0, 0, 0, 0.5);
    content: '';
    position: absolute;
    width: 150%;
    height: 100%;
    margin-top: 48px;
    z-index: 2;
    left: 0;
  }
  .sketch-top {
    display: none;
  }

  .unitBlk_cont {
    min-width: 100%;
  }
  .mobileview {
    display: none !important;
  }
}