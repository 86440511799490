@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i,800,800i&display=swap');

body {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  line-height: 1.5 !important;
}
button,
input,
optgroup,
select,
textarea,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Open Sans', sans-serif !important;
}
.btn.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn.btn-default:focus {
  outline: none;
  box-shadow: none;
}
.link-text {
  color: #4183c4 !important;
}
a {
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ----------------- on of switch */
input[type='checkbox'].toggle {
  opacity: 0;
  position: absolute;
  left: -99999px;
}
input[type='checkbox'].toggle + label {
  height: 26px;
  line-height: 1.9;
  background-color: #000000;
  padding: 0px 10px;
  border-radius: 1.1429rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  /* -moz-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5); */
}
input[type='checkbox'].toggle + label:before,
input[type='checkbox'].toggle + label:hover:before {
  content: ' ';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 23px;
  height: 22px;
  background: #fff;
  z-index: 2;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  -moz-border-radius: 1rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}
input[type='checkbox'].toggle + label .off,
input[type='checkbox'].toggle + label .on {
  color: #fff;
}
input[type='checkbox'].toggle + label .off {
  margin-left: 21px;
  display: inline-block;
}
input[type='checkbox'].toggle + label .on {
  display: none;
}
input[type='checkbox'].toggle:checked + label .off {
  display: none;
}
input[type='checkbox'].toggle:checked + label .on {
  margin-right: 22px;
  display: inline-block;
}
input[type='checkbox'].toggle:checked + label,
input[type='checkbox'].toggle:focus:checked + label {
  background-color: #5abb37;
}
input[type='checkbox'].toggle:checked + label:before,
input[type='checkbox'].toggle:checked + label:hover:before,
input[type='checkbox'].toggle:focus:checked + label:before,
input[type='checkbox'].toggle:focus:checked + label:hover:before {
  background-position: 0 0;
  top: 2px;
  left: 100%;
  margin-left: -25px;
}

.render-tooltip {
  position: relative;
  cursor: pointer;
}
.render-tooltip .tooltiptext {
  visibility: hidden;
  min-width: 300px;
  max-width: 350px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}
.render-tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 575px) {
  input[type='checkbox'].toggle + label:before,
  input[type='checkbox'].toggle + label:hover:before {
    height: 20px;
    width: 20px;
  }
  input[type='checkbox'].toggle + label {
    height: 24px;
    line-height: 24px;
  }
  input[type='checkbox'].toggle + label .off {
    margin-left: 1rem;
  }
  input[type='checkbox'].toggle:checked + label .on {
    margin-right: 1.1429rem;
  }
  input[type='checkbox'].toggle:checked + label:before,
  input[type='checkbox'].toggle:checked + label:hover:before,
  input[type='checkbox'].toggle:focus:checked + label:before,
  input[type='checkbox'].toggle:focus:checked + label:hover:before {
    margin-left: -22px;
  }
}
/* ----------------- on of switch */
/* ------------------------ loader */
/* .timeline-wrapper { background-color: #e9eaed; color: #141823; }
.solounit-main .timeline-item { min-height: 175px !important; }
.timeline-item { background: #fff; border: 1px solid; border-color: #e5e6e9 #dfe0e4 #d0d1d5; border-radius: 3px; padding: 0.857143rem; margin: 0 auto; max-width: 100%; min-height: 200px; }
.animated-background { -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: placeHolderShimmer; animation-name: placeHolderShimmer; -webkit-animation-timing-function: linear; animation-timing-function: linear; background: #f6f7f8; background: #eeeeee; background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee)); background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%); background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%); -webkit-background-size: 800px 104px; background-size: 800px 104px; height: 96px; position: relative; }
.background-masker { background: #fff; position: absolute; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.outlined .background-masker { border: 1px solid #ddd; }
.outlined:hover .background-masker { border: none; }
.outlined:hover .background-masker:hover { border: 1px solid #ccc; z-index: 1; }
.background-masker.header-top, .background-masker.header-bottom, .background-masker.subheader-bottom { top: 0; left: 40px; right: 0; height: 10px; }
.background-masker.header-left, .background-masker.subheader-left, .background-masker.header-right, .background-masker.subheader-right { top: 10px; left: 40px; height: 8px; width: 10px; }
.background-masker.header-bottom { top: 1.28571rem; height: 6px; }
.background-masker.subheader-left, .background-masker.subheader-right { top: 24px; height: 6px; }
.background-masker.header-right, .background-masker.subheader-right { width: auto; left: 70%; right: 0; }
.background-masker.subheader-right { left: 55%; }
.background-masker.subheader-bottom { top: 30px; height: 10px; }
.background-masker.content-top, .background-masker.content-second-line, .background-masker.content-third-line, .background-masker.content-second-end, .background-masker.content-third-end, .background-masker.content-first-end { top: 40px; left: 0; right: 0; height: 6px; }
.background-masker.content-top { height: 20px; }
.background-masker.content-first-end, .background-masker.content-second-end, .background-masker.content-third-end { width: auto; left: 80%; right: 0; top: 60px; height: 8px; }
.background-masker.content-second-line { top: 68px; }
.background-masker.content-second-end { left: 90%; top: 74px; }
.background-masker.content-third-line { top: 82px; }
.background-masker.content-third-end { left: 70%; top: 88px; } */
/* ------------------------ loader */

.modal-header h5 {
  color: #000;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
}

.modal-content i.fa.fa-times {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.modal-header {
  border-bottom: 1px solid #f45a2b;
}