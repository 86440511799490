.streamtoolstoggle{
 
}

.streamtoolstoggle input[disabled] {
  opacity: 0.8 !important;
}

.streamtoolstoggle label[disabled] {
  opacity: 0.5 !important;
}
.single-line-skeleton-70 {
  line-height: 2;
  overflow: hidden;
  max-height: 2em;
  width: 70%;
}
.single-line-skeleton-30 {
  line-height: 2;
  overflow: hidden;
  max-height: 2em;
  width: 30%;
}
.single-line-skeleton {
  line-height: 2;
  overflow: hidden;
  max-height: 2em;
}
.single-line-skeleton-small {
  line-height: 2;
  overflow: hidden;
  max-height: 5px;
}
@media (max-width: 1229px) {
  .sub-title {
   padding-top: 5px !important;
  }
}