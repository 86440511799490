.payment-tooltip .tooltiptext {
  visibility: hidden;
  min-width: 150px;
  max-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}
.payment-tooltip:hover .tooltiptext {
  visibility: visible;
}
.promocode {
  margin-top: 10px;
  margin-bottom: 10px;
}
