.youtubelivechat {
    position: fixed;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    cursor: pointer;
    text-align: center;
  }
  .youtubechatframe {
    width: 50% !important;
    height: 80% !important;
    position: absolute;
    padding: 0 !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    min-width: 300px;
    min-height: 300px;
  }
  .youtubechatframe iframe {
    width: 100% !important;
    height: 100% !important;
  }
  .youtubechatframe .close {
    position: absolute;
    right: 7px;
    top: 7px;
  }
  