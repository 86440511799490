.solounit-main .titlebar {
  height: 130px;
  width: 100%;
  background: #f3f3f3;
}
.wifi {
  display: inline-block;
  width: 28px;
  height: 22px;
  overflow: hidden;
  background: url(../../assets/images/wifi-signal.png) no-repeat 0 0;
  margin: 0px 5px 0px 0px;
}
.wifi-signal-1 {
  background-position: 0 -30px !important;
}
.wifi-signal-2 {
  background-position: 0 -60px !important;
}
.wifi-signal-3 {
  background-position: 0 -90px !important;
}
.wifi-signal-4 {
  background-position: 0 -120px !important;
}
.wifi-signal-5 {
  background-position: 0 -150px !important;
}
.hide {
  display: none !important;
}
.titlebarLeft {
  padding-left: 10px;
}
.progress {
  width: 38px;
  border-radius: 0px;
  overflow: inherit;
  height: 20px;
  background: #ddd;
  margin: 0px;
  padding: 2px;
  background: #fff;
  border: 2px solid #b9c7d6;
  position: relative;
}
.progress:before {
  content: '';
  width: 4px;
  height: 11px;
  background: #b9c7d6;
  margin: 0;
  position: absolute;
  top: 1px;
  right: -5px;
}
.progress .prog-wrap {
  width: 38px;
  overflow: hidden;
  display: block;
  position: relative;
  height: 12px;
}
.progress-bar-v {
  width: 100%;
  position: absolute;
  bottom: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  height: 12px;
}
.battery-status {
  margin: 5px 0 0px 0px;
  display: inline-block;
  vertical-align: top;
}
.editUnitnName {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.onlineTxt {
  background: rgb(37, 201, 59);
  display: inline-block;
  padding: 1px 7px;
  color: #fff;
  border-radius: 3px;
}

.offlineTxt {
  background: #999;
  display: inline-block;
  padding: 1px 7px;
  color: #fff;
  border-radius: 3px;
}
.statusTxt {
  padding: 0 10px;
}
.apnSettings .btn {
  line-height: 1.3;
  padding: 1px 7px;
  color: #fff;
  border-radius: 3px;
}
.smalltxt {
  font-size: 0.857143rem;
  color: #aaa;
  display: block;
}
.statusdiv a.btn:not([href]):not([tabindex]) {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.statusdiv a.btn:hover,
.statusdiv a.btn.btn-danger:hover {
  color: #fff;
  background-color: #e44920;
  border-color: #e44920;
}
.statusdiv a.btn.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.statusdiv a.btn.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.editUnitName input[type='text'] {
  padding: 2px 6px;
}
.status input[disabled] {
  opacity: 0.8 !important;
}

.status label[disabled] {
  opacity: 0.5 !important;
}
.titlebarRight .statusdiv .btn {
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  .titlebarRight {
    position: relative;
    top: -30px;
  }
}
@media (max-width: 768px) {
  .onlineTxt,
  .offlineTxt {
    font-size: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .statusTxt {
    line-height: 0;
    padding-bottom: 5px;
  }
  .apnSettings .btn {
    background: transparent;
    font-size: 0;
    width: 30px;
    height: 25px;
    background-image: url(../../assets/images/settings.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    cursor: pointer;
    display: inline-block;
  }
  .apnSettings .btn:focus,
  .apnSettings .btn:hover,
  .apnSettings .btn:active {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0);
  }

  .titlebarLeft {
    padding-top: 9px;
  }
}

@media (max-width: 575px) {
  .titlebarLeft {
    padding-left: 0;
  }
  .lowdelaymode.d-flex.flex-row {
    /* flex-direction: column !important; */
  }
  .status.d-flex.align-items-md-center {
    align-items: center;
  }
  .solounit-main .titlebar {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .titlebarRight {
    top: -30px;
  }
}

@media (min-width: 767px) {
  .titlebarRight .statusdiv .btn {
    font-size: 1.28571rem;
    font-weight: 600 !important;
    padding: 7px 1.1429rem;
    display: inline-flex;
    align-items: center;
    width: 100%;
    min-width: 103px;
    justify-content: center;
  }
  .titlebarRight .statusdiv .btn > span {
    display: flex;
  }

  .titlebarRight {
    margin-right: 8px;
  }
}
.video-skeleton {
  width: 100%;
}
.custom-skeleton {
  width: 100%;
  color: transparent;
  font-size: 20px;
  background-color: red;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #f2f2f2),
    color-stop(37%, #e6e6e6),
    color-stop(63%, #f2f2f2)
  );
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.btn-skeleton .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

.btn-skeleton.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #e44000),
    color-stop(37%, #f2f2f2),
    color-stop(63%, #e44920)
  );
  /* background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%); */
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
  /* background: gray; */
}

.ant-progress-bg {
  background-color: #e44920 !important;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a !important;
}

.ant-progress-inner {
  background-color: #ddd2d2 !important;
}
