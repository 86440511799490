a.add-lrt {
  font-size: 0.857143rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.currentBitrate a {
  display: none;
}

.f-size-11 {
  font-size: 11px !important;
}

.info-icon {
  color: #ffca00 !important;
  text-decoration: none !important;
}
.lrtStatus_blk .profile-select {
  padding-top: 3px;
  padding-bottom: 3px;
}
.lrtStatus_blk .zone-block {
  padding-top: 3px;
  padding-bottom: 1px;
}
.lrtStatus_blk .info-text {
  line-height: 1.2;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: -9px;
  margin-left: -9px;
}
.lrtStatus_blk {
}
.lrtStatus_blk {
}

.lrtservices a[disabled] {
  opacity: 0.8 !important;
  color: rgb(255, 255, 255);
}

.lrtStatus_blk input[disabled] {
  opacity: 0.8 !important;
}

.lrtStatus_blk label[disabled] {
  opacity: 0.5 !important;
}

.zone-block .valuetxt .selection .text {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ltrAction{
  flex-wrap: wrap;
  gap: 5px;
}
