/* wowza help */
.soloTerms .help-block {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
}
.soloTerms .help-block img {
  max-width: 100%;
}
.soloTerms .card-body {
  padding: 0px 30px 30px;
  margin-bottom: 15px;
}
.soloTerms .btn-row {
  padding: 0px 30px 30px;
  clear: both;
  margin-top: 15px;
  overflow: hidden;
  text-align: center;
}
.soloTerms .page-title .backbtn {
  margin-top: 10px;
}
.soloTerms h3 {
  font-size: 24px;
}
.soloTerms a {
  word-break: break-all;
}

@media (max-width: 768px) {
  .soloTerms h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 10px;
  }
  .soloTerms .card-body {
    padding: 0px 20px 30px;
  }
}
