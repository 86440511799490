/* .welcome h5 {
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.28571rem;
    text-align: left;
}
.iamrobot {
    height: 35px;
    overflow: hidden;
    width: 200px;
} */
.regInput {
  position: relative;
}
.regInput input {
  padding-right: 35px;
}
.regInput .successtick {
  position: absolute;
  top: 10px;
  right: 6px;
  color: #fff;
  background: green;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
}
.customCheckBox.regInputCheck [type='checkbox']:checked + span:after {
  top: 6px;
}
.iamrobot {
  height: 31px;
  overflow: hidden;
  width: 200px;
}
.iamrobot > div {
  margin: -20px 0 0 -10px;
}

.welcome h5 {
  color: #000;
  margin: 5px 0px !important;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.28571rem;
  text-align: left;
}

.login-links-register {
  float: right;
}
.login-links-register a {
  color: #e44920;
  margin: 0px 0px;
  font-size: 1.28571rem;
  font-weight: 300;
}
.login-links-register button.atag {
  color: #f15a2d;
}
.entrySection .leftSide.registation {
  padding: 15px 30px 15px 30px;
}
/* .input-group-addon i {
  margin-left: -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #6c757d;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
@media (max-width: 1024px) {
  .entryContainer {
    width: 100%;
  }
  .loginLang .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(5px, 30px, 0px) !important;
  }
  .loginLang {
    right: 10px;
  }
}
