.unit-contain {
  padding: 0;
  margin-left: 0;
  border-radius: 0;
  min-height: 710px;
  margin-bottom: 0px;
  box-shadow: inset 5px 5px 10px #d6d6d7;
  width: calc(100% - 180px);
}

.mobUnitNav a {
  display: inline-block;
  padding: 15px;
  border-right: 1px solid #e0e0e0;
}

.mobUnitNav a .icon-bar {
  display: block;
  height: 3px;
  width: 22px;
  background-color: #e44920 !important;
}

.mobUnitNav a .icon-bar+.icon-bar {
  margin-top: 4px;
}

.mobUnitNav {
  display: none;
}

.solounit-details {
  padding-top: 15px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.destination-details {
  padding-top: 15px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

/* .block-wrap {
    border: 2px solid #b9c7d5;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 5px 5px 5px rgba(185,199,213,0.4);
} */
.currentBitrate canvas {
  width: 100% !important;
  max-height: 100px !important;
}

.destination-details .addUnit-btn {
  border: 0;
  background: transparent;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  color: #b9c7d5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.destination-details .addUnit-btn p {
  padding: 10px 0;
}

.addUnitBtn_blk {
  height: 230px;
}

.add_block.unitBlk_cont {
  /* min-width: auto; */
}

.destination-details .unitBlk_cont .addUnitBtn_blk.block-wrap {
  border: 2px dashed #b9c7d5;
  min-width: auto;
  /*min-height: 100%;*/
}

@media (max-width: 991px) {
  .unit-contain {
    min-height: 700px;
  }
  
}

@media (max-width: 575px) {
  .mobUnitNav {
    display: flex;
    border-bottom: 1px solid #ddd;
  }

  .mobUnitNav:after {
    content: '';
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: 1;
    top: 47px;
    display: none;
  }

  .solounit-details {
    padding-left: 15px;
    padding-right: 15px;
  }

  .addSolo a {
    flex-direction: row !important;
    align-items: center;
  }

  .addSolo {
    padding: 5px 10px !important;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .addSolo a svg {
    margin-right: 10px;
  }

  .mobUnitNav .selectUnitName {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0 10px;
  }

  .mobUnitNav .selectUnitName img {
    margin-right: 10px;
    height: 30px;
  }

  .destination-details {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 575px) {}