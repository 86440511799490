.lrtpayment {
  padding-top: 15px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.lrtpayment .sub-title {
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  margin: 0 0 10px;
  background: #fff;
  border-bottom: 1px solid #cadae9;
  text-transform: uppercase;
}
.lrtpayment .sub-title h4 {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.lrtpayment .card-body {
  max-width: 615px !important;
  padding: 5px 15px;
  margin: 0 auto;
  width: 100%;
}

.lrtpayment {
  padding: 15px;
}
.lrtpayment .lbltxt {
  line-height: 34px;
}
.lrtpayment .nowrap {
  white-space: nowrap;
}
.lrtpayment .sub-title {
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  margin: 0 0 10px;
  background: #fff;
  border-bottom: 1px solid #cadae9;
  text-transform: uppercase;
}
.lrtpayment .sub-title h4 {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
}
/* .lrtpayment .lrtplan { border-top:1px solid #cadaea; padding:15px 0px; overflow:hidden;} */
.lrtpayment .lrtplan .plan {
  display: inline-block;
  margin-right: 10px;
}

.lrtpayment .promocode {
  padding: 10px 0px 15px;
}

.lrtpayment .lrtplan .cost,
.lrtpayment .lrtplan .tax {
  text-align: right;
  font-size: 15px;
  font-weight: normal;
}

.lrtpayment .taxloading {
  text-align: left;
  right: 0;
  padding-top: 3px;
}

.lrtpayment .lrtplan .total {
  text-align: right;
  font-size: 1.28571rem;
  font-weight: normal;
  border-top: 1px solid #cadaea;
}

.lrtpayment .lrtplan .trygraphics {
  font-style: italic;
  font-size: 13px;
  font-weight: 600;
  color: #777;
}

.lrtpayment .lrtplan .taxtip {
  text-align: right;
  font-style: italic;
  font-size: 13px;
  font-weight: 600;
  color: #777;
}
.lrtpayment .promocode .graphicscode {
  font-style: italic;
  font-size: 13px;
  font-weight: 600;
  color: #777;
  padding: 0px;
}

.lrtpayment .lrtplan .h2Title {
  font-weight: 700;
}
.reseller-code-msg {
  padding: 10px 0px;
  font-size: 0.857143rem;
  font-style: italic;
}
.lrtpayment .lrtplan .appliedcoupon {
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  padding-top: 0;
  display: inline-block;
  color: #47bd3b;
  margin: -5px 0;
}
.lrtpayment .promocode {
  padding: 15px 70px 20px;
  background: #e1e8ef;
  text-align: center;
  margin-bottom: 15px;
}
.lrtpayment .promocode .lbltxt {
  margin-bottom: 5px;
}
.lrtpayment .promocode .btn-primary {
  border-radius: 0px !important;
  margin-left: 0;
  border: 0;
}
.lrtpayment .promocode .form-control.col-sm-9 {
  width: 75%;
  margin-right: 0;
}
.lrtpayment .promodcode-discount span {
  font-size: 1.1429rem;
  float: right;
  margin-left: 10px;
  line-height: 1.1429rem;
}
.lrtpayment .promodcode-discount {
  margin: 0 0 20px 0px;
  text-align: right;
}
.discount_blk * {
  font-style: italic;
  color: #25c93b;
  font-weight: 700;
}
.cost_blk b,
.discount_blk b,
.tax_blk b {
  line-height: 1.6;
}
@media (min-width: 768px) {
  .lrtpayment .zipCode {
    padding-left: 0px;
  }

  .lrtpayment .lrtplan .cost,
  .lrtpayment .lrtplan .tax,
  .lrtpayment .lrtplan .total {
    margin-right: -1%;
    line-height: 1.6;
  }
}
.lrtpayment .btm-row a {
  text-decoration: underline;
}
.lrtpayment .btm-row input {
  vertical-align: top;
  margin: 5px 10px 0 0;
}
.lrtpayment .plan-group b {
  display: inline-block;
  width: 80px;
  font-weight: 600;
}
.lrtpayment .plan-group {
  margin: 5px 0;
}
.lrtpayment .txt-sm {
  font-size: 0.857143rem;
  color: #666;
  font-style: italic;
}
.lrtpayment .red {
  color: #ff0000;
}
.lrtpayment .profile-select-invalid button {
  border-left: 2px solid #e24148;
}
.lrtpayment .profile-select button {
  border-left: 2px solid #25c93b;
}

.lrtpayment .promocode .payment-tooltip {
  position: relative;
  cursor: pointer;
}
.lrtpayment .promocode .payment-tooltip .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  margin: 5px;
  overflow: auto;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
}
.lrtpayment .promocode .payment-tooltip:hover .tooltiptext {
  visibility: visible;
}
.lrtpayment .retryagain {
  padding: 50px 0;
  text-align: center;
  font-size: 21px;
  font-weight: 300;
  border: 1px solid #efefef;
  margin: 20px 20px 20px 20px;
}
.cardHide {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}
.lrtpayment .error {
  padding-left: 0px !important;
}
.lrtpayment .taxLoader {
  text-align: right;
  margin-right: 6px;
}
.visa {
  background-image: url('../../assets/images/cc6.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.mastercard {
  background-image: url('../../assets/images/cc3.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.discover {
  background-image: url('../../assets/images/cc5.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.amex {
  background-image: url('../../assets/images/cc2.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.jcb {
  background-image: url('../../assets/images/cc7.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.dinersclub {
  background-image: url('../../assets/images/cc4.png') !important;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

@media (max-width: 991px) {
  .lrtpayment .promocode .payment-tooltip .tooltiptext {
    right: 0;
    top: 15px;
  }
}
@media (max-width: 768px) {
  .lrtpayment .promocode {
    padding: 15px 40px 20px;
  }
  .radiobtn-responsive {
    display: block !important;
    margin-top: 6px;
  }
}

@media (max-width: 480px) {
  .lrtpayment .promocode {
    padding: 15px 30px 20px;
  }
}
/* new payment workflow */

.multipart-form {
  max-width: 100%;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .multipartform-1-show, .multipart-form-3-show {
    padding: 50px 0px;
} */

.multipartform-1-hide,
.multipartform-2-hide,
.multipartform-3-hide {
  display: none;
}

.multipartform-1-show,
.multipartform-2-show,
.multipartform-3-show {
  display: block;
}
.next-previous-btn {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
}
.default-card {
  padding: 50px 0px;
}

.tabFootNav .StripeElement {
  width: 130px;
}

/* tab ui */
.tabCont .tabDetails {
  margin-top: 5px;
  min-height: 450px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  border-radius: 10px;
  background: #f9f9f9;
}

@media (max-width: 575px) {
  .tabCont .tabDetails {
    padding: 20px 15px;
  }
}
.tabCont .tabFootNav {
  padding-bottom: 15px;
}
.cardDesign {
  max-width: 340px;
  width: 100%;
  height: 180px;
  /* background: #f1f1f1; */
  border: 1px solid #ddd;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  margin: auto;
  background: rgb(228, 73, 32);
  background: linear-gradient(
    133deg,
    rgb(228 73 32) 0%,
    rgba(241, 123, 91, 1) 41%,
    rgba(243, 243, 243, 1) 100%
  );
}

.cardDesign.emptyCard {
  cursor: pointer;
  background: #f1f1f1;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: #ccc;
}
.cardDesign.emptyCard:hover {
  background-color: #eee;
}

.cardDesign .cardNumber {
  font-size: 22px;
  font-weight: 600;
}

.cardDesign .cardHolder {
  font-size: 1.1429rem;
  text-transform: uppercase;
}

.cardDesign .cardType {
  width: 44px;
  height: 36px;
}
.cardTypeSec {
  position: relative;
}
.cardTypeSec:after {
  content: '';
  height: 30px;
  width: 40px;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  display: block;
  position: absolute;
  right: 5px;
  top: 2px;
  z-index: 1;
}
.cardTypeSec[data-type='visa']:after {
  background-image: url('../../assets/images/cc6.png') !important;
}
.cardTypeSec[data-type='mastercard']:after {
  background-image: url('../../assets/images/cc3.png') !important;
}
.cardTypeSec[data-type='discover']:after {
  background-image: url('../../assets/images/cc5.png') !important;
}
.cardTypeSec[data-type='amex']:after {
  background-image: url('../../assets/images/cc2.png') !important;
}
.cardTypeSec[data-type='jcb']:after {
  background-image: url('../../assets/images/cc7.png') !important;
}
.cardTypeSec[data-type='dinersclub']:after {
  background-image: url('../../assets/images/cc4.png') !important;
}

/* tab ui */

.restrict-licence-key {
  color: rgb(240, 9, 9) !important;
}
.notValidAgreement {
  top: 10%;
  left: 25%;
  color: rgb(228, 73, 32);
  padding: 7px 15px;
  border: 1px solid rgb(228, 73, 32);
  border-radius: 5px;
  background: #ff000012;
}
.notValidAgreementSpan {
  position: absolute;
  top: 10%;
  left: 44%;
  color: rgb(228, 73, 32);
  padding: 7px 15px;
  border: 1px solid rgb(228, 73, 32);
  border-radius: 5px;
  background: #ff000012;
}

.disabledbutton {
  display: none;
}

.font-size {
  font-style: italic;
  color: #6c757d !important;
}
.regionCountry {
  display: inline-block;
}
.regionCountrySelect {
  width: 16rem;
  margin: 0px 2px;
}
.infoIcon {
}
.countryLable {
  display: inline-block;
  font-weight: 700;
  min-width: 8rem;
}
.helpLink {
  text-decoration: none !important;
}

.modal-footer {
  border-top: 0px !important;
}
.stripButton {
  width: 100%;
  max-width: 340px;
  height: 40px;
  background: #32325d;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stripButton:hover {
  background-color: #474783;
}

.chackBoxGroup {
  position: relative;
}
.chackBoxGroup input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.chackBoxGroup .chackBox {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  display: block;
}
.chackBoxGroup .chackBox:before,
.chackBoxGroup .chackBox:after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #e4491f;
  display: block;
  opacity: 0;
  transition: all 0.6s;
}

.chackBoxGroup .chackBox:before {
  height: 6px;
  top: 8px;
  left: 4px;
  transform: rotate(314deg);
}
.chackBoxGroup .chackBox:after {
  height: 12px;
  top: 3px;
  left: 9px;
  transform: rotate(45deg);
}
.chackBoxGroup input[type='radio']:checked + .chackBox:before,
.chackBoxGroup input[type='radio']:checked + .chackBox:after {
  opacity: 1;
}
.disabledPaymentButton {
  pointer-events: none;
  opacity: 0.4;
}
.sendInvoiceMail {
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sendInvoiceMailBtn {
  padding-left: 37px;
  min-height: 38px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.sendInvoiceMailBox {
  margin: 70px 0 0;
}
.sendInvoiceMailBox .sendInvoiceMailBtn {
  background: rgb(228, 73, 32);
  background: linear-gradient(
    133deg,
    rgb(228 73 32) 0%,
    rgba(241, 123, 91, 1) 41%,
    rgba(243, 243, 243, 1) 100%
  );
}

.notesTxt {
  margin: 5px 0px 0px 0px;
  display: block;
  font-size: 13px;
  font-style: italic;
  clear: both;
}
.notesTxt i {
  color: #1677ff;
  font-size: 14px;
}
