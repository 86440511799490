.customrtmp-popup h1 {
  color: #000;
  font-size: 24px;
  font-weight: 300;
}
.customrtmp-popup .modal-header {
  border-bottom: 1px solid #f1592b;
}
.customrtmp-popup .modal-footer {
  clear: both;
}
.customrtmp .plus-minus span:before {
  font-family: FontAwesome;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  content: '\f077';
}
.customrtmp .page-title h5 {
  width: 100%;
  float: left;
  font-size: 1.28571rem;
  font-weight: 300;
  color: #000;
  margin: 10px 0 10px;
}
.customrtmp .plus-minus {
  padding: 2px 7px;
  border-radius: 0px;
  border: none;
}
.customrtmp .btn-default.plus-minus:focus,
.customrtmp .btn-default.plus-minus:active,
.customrtmp .btn-default.plus-minus:hover {
  background-color: transparent !important;
  box-shadow: none;
}
.customrtmp .plus-minus.collapsed span:before {
  content: '\f078';
}
.customrtmp .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -45px;
}
.customrtmp {
  position: relative;
}
.customrtmp .card-body {
  margin: 0 auto;
}
.customrtmp .page-wrap {
  margin-bottom: 20px;
}

.customrtmp .form-group::after {
  content: '';
  clear: both;
  width: 100%;
  display: block;
}
.customrtmp .upBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.customrtmp .downBtn {
  padding: 0px 0.857143rem;
  position: absolute;
  top: 17px;
  right: 1px;
  line-height: 1.1429rem;
  z-index: 0;
  border: none;
  background: #fff;
  border-radius: 0;
}
.customrtmp .upBtn:focus,
.twitch .downBtn:focus {
  border: none;
  outline: none;
}
.customrtmp .bitrate {
  position: relative;
}
.customrtmp .framerate {
  position: relative;
}
.customrtmp .col-left {
  font-size: 1.1429rem;
  font-weight: 300;
}
.customrtmp .col-left .lbltxt {
  display: inline-block;
  line-height: 34px;
}
.customrtmp .col-left .error {
  margin-top: 5px;
  clear: both;
}
.customrtmp .col-left .notesTxt {
  font-size: 13px;
  line-height: 1.1429rem;
  display: block;
  margin: 10px 0;
}
.customrtmp .profile-settings .error {
  position: absolute;
  top: 0.857143rem;
  left: 230px;
  width: 300px;
}
.customrtmp .bitrate-input {
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
}
.customrtmp .bitrate-input input {
  border: none;
}
.customrtmp .profile-select-invalid button {
  border-left: 2px solid #e24148;
}
.customrtmp .profile-select button {
  border-left: 2px solid #25c93b;
}
.customrtmp .profile-settings .error {
  position: relative;
  top: 5px;
  left: 0px;
}
.customrtmp .reset {
  position: relative;
  min-height: 0px !important;
}
.customrtmp .btn-row {
  border-top: 1px solid #e2e7eb;
  padding: 20px 30px 0px;
  text-align: right;
  clear: both;
}
.customrtmp .page-title .backbtn {
  margin-top: 10px;
}

.customrtmp .custDropdown .custDropdown__control {
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-left-width: 2px;
  background: #fff;
  height: 31px;
}
.customrtmp .custDropdown__indicator-separator {
  display: none;
}
.high-bit-rate-warn {
  color: #ed4747;
  font-size: 13px;
}
.latenctInfo {
  font-size: 1.50em !important;
  line-height: 0.75em;
  vertical-align: -15%;
  margin-top: 35%;
}
@media (max-width: 992px) {
  .infoRow {position: relative;}
  .infoRow .select-form-control { margin-right: 50px; }
  .infoRow .latenctInfo { position: absolute; top:30px; right:15px;     margin: 0;  }
  
  }