.unit-item {
  position: relative;
  overflow: hidden;
  min-width: 190px;
  cursor: pointer;
  max-height: 205px;
  border-left: 6px solid transparent;
}
.unit-item:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 11px solid #e44920;
  position: absolute;
  left: 0px;
  display: none;
}
.unit-item::after {
  height: 2px;
  content: '';
  width: 80%;
  margin: 0 auto;
  background: #e2e7eb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.unit-item.active {
  border-left: 6px solid #e44920;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.unit-item.active:before {
  display: block;
}
.unit-item.active .item-desc {
  color: #e44920;
}
.svg_setting {
  fill: #e44920;
}
.svg_restart {
  fill: red;
}
.unitSettings {
  position: absolute;
  top: 5px;
  left: 8px;
  z-index: 2;
}
.svg_restart {
  fill: red;
}
.unitImgitem_link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.nowLiveTxt {
  color: #fc001a;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  display: block;
}
.nowLiveTxt:before {
  content: '';
  width: 11px;
  height: 11px;
  display: inline-block;
  background: #fc001a;
  border-radius: 100%;
  margin-right: 8px;
}

.item-desc {
  font-size: 1.1429rem;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.item-desc span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  padding-right: 2px;
}
.solounit-main .solounit-contain .offline {
  opacity: 0.5;
}
.word-break {
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
}

.popoverSetting {
  position: absolute;
  top: 7px;
  z-index: 2;
  right: 20px;
  align-items: center;
  display: flex;
}

.popoverOptionName {
  font-size: 16px;
    font-weight: 500;
    line-height: 1.8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-Bottom: '8px';
}
.ant-popover-inner {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) !important;
}
@media (max-width: 575px) {
  .nowLiveTxt {
    margin-top: 0px;
    padding-left: 10px;
    font-size: 1rem;
  }
  .unitImgitem img {
    height: 34px;
  }
  .unitSettings {
    position: absolute;
    top: auto;
    left: auto;
    bottom: auto;
    right: 8px;
  }
  .unit-item::after {
    height: 1px;
  }
  .item-desc span {
    width: calc(100% - 40px);
  }
  .item-desc {
    text-align: left;
  }
  .nowlivebox {
    position: absolute;
    top: 0;
    /* right: 7px; */
    left: 30px
  }
  .unit-item {
    overflow: initial;
  }
  .popoverSetting {
    z-index: 9;
  }
}
