.emojis_blk {
  border-radius: 5px;
}
.emoji i {
  padding: 5px;
  font-size: 15px;
  background: #ddd;
  border-radius: 50%;
  line-height: 1;
}
.emoji {
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 6px;
}
.emoji.eicon {
  top: -2px;
}
.emoji span {
  position: absolute;
  top: -10px;
  left: 60%;
  text-align: center;
  background-color: green;
  min-width: 15px;
  height: 15px;
  border-radius: 10px;
  padding: 0px 3px;
  color: #ffffff;
  font-size: 11px;
  /* z-index: 1; */
}
.emojiPop i.fa {
  padding: 5px;
  background: #ddd;
  border-radius: 50%;
  line-height: 1;
  font-size: 25px;
}
.emojiPop {
  padding-top: 10px;
  padding-bottom: 10px;
}
.emojiPop .nav-tabs .nav-link {
  padding-left: 10px;
  padding-right: 10px;
}
.emojiPop .tab-content {
  min-height: 200px;
  padding-left: 10px;
  padding-right: 10px;
}
.emojiPop .tab-content ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}
.emojiPop .tab-content ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fff;
}
.emojiPop .tab-content ul li:nth-child(odd) {
  background-color: #f1f1f1;
}
.analyticsNoData {
  font-size: 0.857143rem;
  font-style: italic;
}
.emojis_blk .loadytchat .btn-sm {
  font-size: 10px;
  padding: 4px 9px;
  margin-left: 8px;
}

.modal-dialog.viewCommentFb {
  max-width: 530px;
}
