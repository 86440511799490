.bandwidth{
  margin-top: 3px;
}
.bandwidth *{
  font-size: 9px;
  line-height: 1;
}
.bandwidth .ant-badge-status-text{
  font-size: 9px;
  line-height: 1;
  font-weight: 400;
  color: #333;
  font-weight: 600;
}
.bandwidth .ant-badge-status{
  display: inline-flex;
  align-items: center;}
  .bandwidth  .ant-badge-status-dot{
    top: auto;
  }
.current-connection {
  vertical-align: baseline;
  font-size: 23px;
  font-weight: 700;
  margin-left: 6px;
}
.current-connection-lbl {
  margin-left: 6px;
}
.cntCol {
  margin-left: 8px;
}
.badge-ellipsis .ant-badge-status-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70px;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.expanded-textarea {
  height: 200px;
  resize: none;
}